import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { path, prop } from 'ramda';
import styled from '@emotion/styled';
import theme from '@greenberry/salal/theme';
import Heading from '@greenberry/salal/components/Heading';
import HeroV4 from '@greenberry/salal/components/Hero/v4';
import Section from '@greenberry/salal/components/Section';
import ContactElement from '@greenberry/salal/components/Contact';
import IconedList from '@greenberry/salal/components/IconedList';
import Text from '@greenberry/salal/components/Text';
import MainWrapper from '@greenberry/salal/components/MainWrapper';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const IconsWrapper = styled.div`
  color: ${theme.color('primary.1')};
  font-weight: ${theme.get('type.fontWeight.bold')};
`;

const Contact = () => {
  const { datoCmsContact: copy } = useStaticQuery(graphql`
    query getContact {
      datoCmsContact {
        title
        intro
        poBody
        poEmail
        poPhone
        poWebsite
        voBody
        voEmail
        voPhone
        voWebsite
        seoMetaTags {
          tags
        }
      }
    }
  `);

  const {
    poBody,
    poEmail,
    poPhone,
    poWebsite,
    voBody,
    voEmail,
    voPhone,
    voWebsite,
  } = copy;

  const PoAddressData = {
    phonenumber: poPhone,
    email: poEmail,
    website: poWebsite,
  };

  const VoAddressData = {
    phonenumber: voPhone,
    email: voEmail,
    website: voWebsite,
  };

  return (
    <Layout>
      <SEO
        title={prop('title', copy)}
        tags={path(['seoMetaTags', 'tags'], copy)}
      />
      <HeroV4
        content={
          <>
            <Heading component="h1" size="xxxl" weight="semibold">
              {prop('title', copy)}
            </Heading>
            <Text size="m">{prop('intro', copy)}</Text>
          </>
        }
      />
      <MainWrapper>
        <Section size="1">
          <ContactElement>
            <Heading component="h1" size="xxl" appearance="secondary">
              {'PO-raad'}
            </Heading>
            <Text size="m" appearance="base">
              {poBody}
            </Text>
            <IconsWrapper>
              <IconedList data={PoAddressData} orientation="horizontal" />
            </IconsWrapper>
          </ContactElement>
        </Section>
        <Section>
          <ContactElement>
            <Heading component="h1" size="xxl" appearance="secondary">
              {'VO-raad'}
            </Heading>
            <Text size="m" appearance="base">
              {voBody}
            </Text>
            <IconsWrapper>
              <IconedList data={VoAddressData} orientation="horizontal" />
            </IconsWrapper>
          </ContactElement>
        </Section>
      </MainWrapper>
    </Layout>
  );
};

export default Contact;
